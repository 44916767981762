import React, { Fragment, useEffect, useMemo, useState } from "react";
import styles from "../styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getByDayOfWeekOverview,
  getOverviewReport,
  getPlanByDayOfWeek,
  getPlanOtaRanking,
  getPlanRoomTypeRanking,
  getPlanTopRoomType,
  getRankingByPrice,
} from "../../../features/report/reportSlice";
import {
  ChartAreaBorder,
  ChartBackgroundColor,
  CurrencyFormat,
  getDefaultDateRange,
  getStartEndDateString,
  triggerDownload,
} from "../../../helper";
import { Box, Typography, CircularProgress, Button } from "@mui/material";
import ReportBarChart from "../../../components/ReportHorizontalChart";
import ReportStackBarChart from "../../../components/ReportHorizontalChart/ReportStackBarChart";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LineController,
  PointElement,
} from "chart.js";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import html2canvas from "html2canvas";
import ReportChartsDocument from "../../../components/pdf/ReportChartsDocument";
import { usePDF } from "@react-pdf/renderer";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartBackgroundColor,
  ChartAreaBorder,
  LineElement,
  LineController,
  PointElement
);

const DEFAULT_PRICE_RANGE = [
  {
    to: 10000,
    color: "#E5D6F7",
  },
  {
    to: 20000,
    color: "#BB86FC",
  },
  {
    to: 30000,
    color: "#6210EE",
  },
  {
    to: 40000,
    color: "#3709B3",
  },
  {
    to: 40001,
    color: "#23046A",
  },
];

const LoadingReport = () => (
  <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
    <CircularProgress />
  </Box>
);

const ReportOverviewPdf = (props) => {
  const { dateRange } = props;

  const chartLabels = {
    top_group_plans_number_nights: {
      title: "プランランキング",
      name_col: "プラングループ名",
      count_col: {
        id: "number_nights",
        label: "泊数",
      },
      revenue_col: "total_fee",
    },
    top_lead_time_ranking: {
      title: "リードタイムランキング",
      name_col: "リードタイム",
      count_col: {
        id: "number_nights",
        label: "泊数",
      },
      revenue_col: "total_fee",
    },
    top_room: {
      title: "ルームタイプ別ランキング",
      name_col: "ルームタイプ名",
      count_col: {
        id: "number_nights",
        label: "泊数",
      },
      revenue_col: "total_fee",
    },
    top_nights_stay: {
      title: "泊数/滞在別",
      name_col: "泊数",
      count_col: {
        id: "plan_count",
        label: "件数",
      },
      revenue_col: "total_fee",
    },
  };

  const colorConfig = {
    count_col: "#7764E4",
    revenue_col: "#CE858F",
  };

  const dispatch = useDispatch();
  const { data: reportData, loading } = useSelector((state) => state.report);
  const [maxValue, setMaxValue] = useState({});

  useEffect(() => {
    dispatch(getOverviewReport(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    const max = {};
    Object.keys(reportData).forEach((reportKey) => {
      reportData[reportKey].forEach((row) => {
        const countCol = chartLabels[reportKey]?.count_col?.id;
        const revenueCol = "total_fee";

        const countValue = row[countCol];
        const revenueValue = row[revenueCol];
        if (!max[reportKey]) {
          max[reportKey] = {};
        }
        if (max[reportKey].count < countValue || !max[reportKey].count) {
          max[reportKey].count = countValue;
        }

        if (
          max[reportKey][revenueCol] < revenueValue ||
          !max[reportKey][revenueCol]
        ) {
          max[reportKey][revenueCol] = revenueValue;
        }
      });
    });
    setMaxValue(max);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  const convertToDataChart = (data, chartKey) => {
    return [
      {
        value: data[chartLabels[chartKey]?.count_col?.id] || 0,
        color: colorConfig.count_col,
        id: "count",
      },
      {
        value: data.total_fee,
        color: colorConfig.revenue_col,
        id: "total_fee",
      },
    ];
  };

  return (
    <>
      {loading.overview ? (
        <LoadingReport />
      ) : (
        <Box className={styles["report-items"]}>
          {Object.entries(reportData).map(([key, chartData]) => (
            <Box className={styles["report-item"]} key={key}>
              <Typography className={styles["report-item-title"]}>
                {chartLabels[key]?.title || "未知のグラフタイプ"}
              </Typography>
              <table className={styles["report-table"]}>
                <thead>
                  <tr>
                    <th>{chartLabels[key]?.name_col || "名前"}</th>
                    <th>{chartLabels[key]?.count_col?.label || "件数"}</th>
                    <th>売上</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from(chartData)?.map((row, index) => (
                    <React.Fragment key={index}>
                      <tr className={styles["detail-row"]}>
                        <td>
                          <p>{row.label_name}</p>
                        </td>
                        <td>
                          {CurrencyFormat(row[chartLabels[key]?.count_col?.id])}
                        </td>
                        <td>¥{CurrencyFormat(row["total_fee"])}</td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <ReportBarChart
                            maxData={maxValue[key]}
                            data={convertToDataChart(row, key)}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

const ReportPlanRankingPdf = (props) => {
  const { dateRange } = props;
  const dispatch = useDispatch();
  const { planOta: reportData, loading } = useSelector((state) => state.report);
  const [transformData, setTransformData] = useState([]);
  const [labels, setLabels] = useState({});
  const [maxCount, setMaxCount] = useState(0);

  useEffect(() => {
    dispatch(getPlanOtaRanking(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    const labels = {};
    let maxCount = 0;
    const transformData = reportData?.map((item) => {
      labels[item.total.title] = item.total.color;
      if (item.total.number_nights > maxCount) {
        maxCount = item.total.number_nights;
      }
      return {
        ...item,
        total: {
          color: item.total.color,
          title: item.total.title,
          value: item.total.number_nights,
        },
        groups: item?.groups?.map((group) => {
          labels[group.title] = group.color;
          return {
            color: group.color,
            title: group.title,
            value: group.number_nights,
          };
        }),
      };
    });
    setLabels(labels);
    setTransformData(transformData);
    setMaxCount(maxCount);
  }, [reportData]);

  return (
    <>
      {loading.planOta ? (
        <LoadingReport />
      ) : (
        <Box className={styles["report-ranking"]}>
          <table
            className={`${styles["report-table"]} ${styles["report-table--ranking"]}`}
          >
            <thead>
              <tr>
                <th>プラングループ名</th>
                <th>泊数</th>
                <th>予約件数</th>
                <th>平均単価</th>
                <th>売上</th>
              </tr>
            </thead>
            <tbody>
              {transformData.map((plan, index) => (
                <Fragment key={index}>
                  <tr className={styles["detail-row"]}>
                    <td>{plan.name}</td>
                    <td>{CurrencyFormat(plan.total?.value)}</td>
                    <td>{CurrencyFormat(plan.number_reservations)}</td>
                    <td>¥{CurrencyFormat(plan.avarege_price)}</td>
                    <td>¥{CurrencyFormat(plan.total_fee)}</td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <ReportStackBarChart data={plan} maxValue={maxCount} />
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
          <Box className={styles["grouped-labels"]}>
            {Object.entries(labels).map(([title, color], index) => (
              <Box className={styles["grouped-label"]} key={index}>
                <Box
                  className={styles["grouped-label-color"]}
                  sx={{ backgroundColor: color }}
                ></Box>
                <span>{title}</span>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

const ReportPlanRoomTypeRankingPdf = (props) => {
  const { dateRange } = props;
  const dispatch = useDispatch();
  const { planRoomType: reportData, loading } = useSelector(
    (state) => state.report
  );
  const [transformData, setTransformData] = useState([]);
  const [maxCount, setMaxCount] = useState(0);

  useEffect(() => {
    dispatch(getPlanRoomTypeRanking(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    let maxCount = 0;
    const transformData = reportData.map((item) => {
      const reportData = item.report_data;
      const total = reportData[0];
      const groups = reportData.slice(1);
      maxCount = Math.max(maxCount, total.value);
      return {
        ...item,
        total,
        groups,
      };
    });
    setMaxCount(maxCount);
    setTransformData(transformData);
  }, [reportData]);

  return (
    <>
      {loading.planRoomType ? (
        <LoadingReport />
      ) : (
        <Box className={styles["report-ranking"]}>
          <table
            className={`${styles["report-table"]} ${styles["report-table--ranking"]}`}
          >
            <thead>
              <tr>
                <th>プラングループ名</th>
                <th>泊数</th>
                <th>予約件数</th>
                <th>平均単価</th>
                <th>売上</th>
              </tr>
            </thead>
            <tbody>
              {transformData.map((report, index) => (
                <Fragment key={index}>
                  <tr className={styles["detail-row"]}>
                    <td>{report.group_plan_name}</td>
                    <td>{CurrencyFormat(report.number_nights)}</td>
                    <td>{CurrencyFormat(report.number_reservations)}</td>
                    <td>¥{CurrencyFormat(report.avarege_price)}</td>
                    <td>¥{CurrencyFormat(report.reservation_amount)}</td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <ReportStackBarChart data={report} maxValue={maxCount} />
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
          <Box className={styles["grouped-labels"]}>
            <Box className={styles["grouped-label"]}>
              <Box
                className={styles["grouped-label-color"]}
                sx={{ backgroundColor: transformData[0]?.total.color }}
              ></Box>
              <span>{transformData[0]?.total.title}</span>
            </Box>
            {transformData[0]?.groups.map((item, index) => (
              <Box className={styles["grouped-label"]} key={index}>
                <Box
                  className={styles["grouped-label-color"]}
                  sx={{ backgroundColor: item.color }}
                ></Box>
                <span>{item.title}</span>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

const ReportPlanByDayOfWeekPdf = (props) => {
  const { dateRange } = props;
  const dispatch = useDispatch();
  const { planByWeek: reportData, loading } = useSelector(
    (state) => state.report
  );
  const [transformData, setTransformData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    dispatch(getPlanByDayOfWeek(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    let maxValue = 0;
    const transformData = reportData
      ?.map((item) => {
        if (item.total.count > maxValue) {
          maxValue = item.total.count;
        }
        const days = Object.values(item.days);
        return {
          ...item,
          max_count_days: Math.max(...days),
          total: {
            color: item.total.color,
            value: item.total.count,
            title: item.total.group_plan_name,
          },
        };
      })
      .sort((a, b) => {
        return b.total.value - a.total.value;
      });
    setTransformData(transformData);
    setMaxValue(maxValue);
  }, [reportData]);

  const calculateOpacity = (value, maxValue) => {
    return value / (maxValue || 1);
  };

  const weekDays = [
    {
      id: "sun",
      title: "日曜日",
    },
    {
      id: "mon",
      title: "月曜日",
    },
    {
      id: "tue",
      title: "火曜日",
    },
    {
      id: "wed",
      title: "水曜日",
    },
    {
      id: "thu",
      title: "木曜日",
    },
    {
      id: "fri",
      title: "金曜日",
    },
    {
      id: "sat",
      title: "土曜日",
    },
  ];

  return (
    <>
      {loading.planByWeek ? (
        <LoadingReport />
      ) : (
        <Box className={styles["report-ranking"]}>
          <table
            className={`${styles["report-table"]} ${styles["report-table--ranking"]} ${styles["report-table--by-days"]}`}
          >
            <thead>
              <tr>
                <th>プラングループ名</th>
                {weekDays.map((day, index) => (
                  <th key={index}>{day.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {transformData.map((item, index) => (
                <Fragment key={index}>
                  <tr className={styles["detail-row"]}>
                    <td>
                      {item.total.title}
                      <div className={styles["chart-container"]}>
                        <ReportStackBarChart
                          data={item}
                          limitWidth={1}
                          maxValue={maxValue}
                        />
                      </div>
                    </td>
                    {weekDays.map((day, dayIndex) => {
                      const opacity =
                        calculateOpacity(
                          item.days[day.id],
                          item.max_count_days
                        ) || 0.05;
                      return (
                        <td key={dayIndex}>
                          <Box
                            className={styles["cell-date"]}
                            sx={{
                              opacity,
                            }}
                          ></Box>
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </>
  );
};
const ReportRoomTypeRankingPdf = (props) => {
  const { dateRange } = props;

  const dispatch = useDispatch();
  const { roomType: reportData, loading } = useSelector(
    (state) => state.report
  );
  const [transformData, setTransformData] = useState([]);
  const [maxCount, setMaxCount] = useState(0);

  useEffect(() => {
    dispatch(getPlanTopRoomType(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    let maxCount = 0;
    const transformData = reportData
      .map((item) => {
        if (item.value > maxCount) {
          maxCount = item.value;
        }
        return {
          ...item,
          total: {
            color: item.color,
            title: item.title,
            value: item.value,
          },
        };
      })
      .sort((a, b) => {
        return b.value - a.value;
      });
    setTransformData(transformData);
    setMaxCount(maxCount);
  }, [reportData]);

  return (
    <>
      {loading.roomType ? (
        <LoadingReport />
      ) : (
        <Box className={styles["report-ranking"]}>
          <table
            className={`${styles["report-table"]} ${styles["report-table--ranking"]} ${styles["report-table--by-days"]}`}
          >
            <thead>
              <tr>
                <th>プラングループ名</th>
                <th>泊数</th>
                <th>予約件数</th>
                <th>平均単価</th>
                <th>売上</th>
              </tr>
            </thead>
            <tbody>
              {transformData.map((item, index) => (
                <Fragment key={index}>
                  <tr className={styles["detail-row"]}>
                    <td>
                      {item.title}
                      <div className={styles["chart-container"]}>
                        <ReportStackBarChart
                          data={item}
                          limitWidth={1}
                          maxValue={maxCount}
                        />
                      </div>
                    </td>
                    <td>{CurrencyFormat(item.value)}</td>
                    <td>{CurrencyFormat(item.number_reservations)}</td>
                    <td>¥{CurrencyFormat(item.average_price)}</td>
                    <td>¥{CurrencyFormat(item.total_fee)}</td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
          <Box className={styles["grouped-labels"]}>
            <Box className={styles["grouped-label"]}>
              <Box
                className={styles["grouped-label-color"]}
                sx={{ backgroundColor: "#172B4D" }}
              ></Box>
              <span>泊数</span>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

const ReportByPricePdf = (props) => {
  const { dateRange, priceRanges } = props;
  const dispatch = useDispatch();
  const { prices: reportData, loading } = useSelector((state) => state.report);
  const [chartData, setChartData] = useState(undefined);
  const options = {
    layout: {
      padding: 8,
    },
    plugins: {
      legend: {
        display: false,
      },
      chartAreaBorder: {
        borderColor: "#D8D8E3",
        borderWidth: 1,
      },
      chartBg: {
        color: "#F8F9FD",
      },
    },
    responsive: true,
    datasets: {
      bar: {
        barPercentage: 0.4,
      },
      line: {
        pointHitRadius: 0,
        pointBorderWidth: 0,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "#2E4E76",
          font: {
            weight: "500",
          },
        },
        grid: {
          color: "transparent",
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: "#2E4E76",
          font: {
            weight: "500",
          },
        },
        grid: {
          color: "transparent",
        },
      },
    },
  };
  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchReportData = (prices, dates = dateRange) => {
    dispatch(
      getRankingByPrice({
        price_ranges: prices,
        ...getStartEndDateString(dates),
      })
    );
  };

  useEffect(() => {
    const times = [];
    const datasets = [];
    if (reportData?.length) {
      const groupData = {};
      reportData.forEach((report) => {
        times.push(report.time_label);
        const dataPriceRanges = report.price_ranges;
        const priceRangeList = report.price_ranges_list;

        priceRanges.forEach((range, rangeIndex) => {
          const key = range.to ?? "other";
          if (!groupData[key]) {
            groupData[key] = {
              color: range.color,
              data: [dataPriceRanges[rangeIndex] || null],
              label: priceRangeList[rangeIndex],
            };
          } else {
            groupData[key].data.push(dataPriceRanges[rangeIndex] || null);
          }
        });
      });

      const arrayReverseGroupData = Object.keys(groupData)
        .sort()
        .reverse()
        .map((key) => groupData[key]);

      arrayReverseGroupData.forEach((group) => {
        datasets.push({
          label: group.label,
          data: group.data,
          backgroundColor: group.color,
        });
        datasets.push({
          label: group.label,
          data: group.data,
          borderColor: group.color,
          type: "line",
          fill: false,
        });
      });
      setChartData({
        labels: times,
        datasets,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  return (
    <>
      {loading.prices ? (
        <LoadingReport />
      ) : (
        <Box className={styles["report-ranking"]}>
          {chartData && <Bar options={options} data={chartData} />}
        </Box>
      )}
    </>
  );
};

const ReportByDayOfWeekPdf = (props) => {
  const { dateRange, priceRanges } = props;
  const options = {
    layout: {
      padding: 8,
    },
    plugins: {
      legend: {
        display: false,
      },
      chartAreaBorder: {
        borderColor: "#D8D8E3",
        borderWidth: 1,
      },
      chartBg: {
        color: "#F8F9FD",
      },
    },
    responsive: true,
    datasets: {
      bar: {
        barPercentage: 0.6,
      },
      line: {
        pointHitRadius: 0,
        pointBorderWidth: 0,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "#2E4E76",
          font: {
            weight: "500",
          },
        },
        grid: {
          color: "transparent",
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: "#2E4E76",
          font: {
            weight: "500",
          },
        },
        grid: {
          color: "transparent",
        },
      },
    },
  };

  const weekDays = [
    {
      id: "sun",
      title: "SUN",
    },
    {
      id: "mon",
      title: "MON",
    },
    {
      id: "tue",
      title: "TUE",
    },
    {
      id: "wed",
      title: "WED",
    },
    {
      id: "thu",
      title: "THU",
    },
    {
      id: "fri",
      title: "FRI",
    },
    {
      id: "sat",
      title: "SUT",
    },
  ];
  const labels = weekDays.map((item) => item.title);
  const weekDayIds = weekDays.map((item) => item.id);
  const INIT_DATA = {
    labels: labels,
    datasets: [],
  };
  const dispatch = useDispatch();
  const { dayOfWeek: reportData, loading } = useSelector(
    (state) => state.report
  );
  const [dataReservation, setDataReservation] = useState({ ...INIT_DATA });
  const [dataReservationPrice, setDataReservationPrice] = useState({
    ...INIT_DATA,
  });
  const [dataReservationOverview, setDataReservationOverview] = useState({
    ...INIT_DATA,
  });
  const [dataByPrices, setDataByPrices] = useState({
    ...INIT_DATA,
  });
  const [legendLabels, setLegendLabels] = useState([]);

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchReportData = (prices = "", dates = dateRange) => {
    dispatch(
      getByDayOfWeekOverview({
        price_ranges: prices,
        ...getStartEndDateString(dates),
      })
    );
  };

  const getReportData = (reportData, valueKey = "total_reservations") => {
    const reportLabels = {};
    const numberReservations = {};
    weekDayIds.forEach((weekDay, weekDayIndex) => {
      reportData[weekDay]?.forEach((report) => {
        if (!numberReservations[report.room_type]) {
          reportLabels[report.room_type] = {
            title: report.room_type,
            color: report.color,
          };
          numberReservations[report.room_type] = {
            label: report.room_type,
            backgroundColor: report.color,
            data: Array.from({ length: weekDayIds.length }, (_) => 0),
          };
        }
        numberReservations[report.room_type].data[weekDayIndex] =
          report[valueKey];
      });
    });

    return {
      legendLabels: reportLabels,
      datasets: Object.values(numberReservations),
    };
  };

  const getReportByPriceData = (
    reportData,
    priceKey = "price_range",
    valueKey
  ) => {
    const chartData = {};
    weekDayIds.forEach((weekDay, weekDayIndex) => {
      reportData[weekDay]?.forEach((report) => {
        if (!chartData[report[priceKey]]) {
          chartData[report[priceKey]] = {
            label: report[priceKey],
            backgroundColor: report.color,
            data: Array.from({ length: weekDayIds.length }, (_) => 0),
          };
        }
        chartData[report[priceKey]].data[weekDayIndex] = report[valueKey];
      });
    });
    const orderedNumberReservations = Object.keys(chartData)
      .sort((a, b) => b.localeCompare(a))
      .reduce((obj, key) => {
        obj[key] = chartData[key];
        return obj;
      }, {});

    return Object.values(orderedNumberReservations);
  };

  const setReportPriceData = (
    topReservationOverview,
    topByWeekdayPriceRange
  ) => {
    if (topReservationOverview) {
      const reportData = getReportByPriceData(
        topReservationOverview,
        "price_range",
        "number_reservations"
      );
      setDataReservationOverview({
        labels,
        datasets: reportData,
      });
    }
    if (topByWeekdayPriceRange) {
      const reportData = getReportByPriceData(
        topByWeekdayPriceRange,
        "range",
        "number_nights"
      );
      setDataByPrices({
        labels,
        datasets: reportData,
      });
    }
  };

  const setReportNumberReservations = (
    reportNumberReservations,
    reportNumberReservationsByPrice
  ) => {
    let reportLabels = {};
    if (reportNumberReservations) {
      const { legendLabels, datasets } = getReportData(
        reportNumberReservations
      );
      reportLabels = Object.values(legendLabels);
      setDataReservation({
        labels,
        datasets,
      });
    }
    if (reportNumberReservationsByPrice) {
      const { legendLabels, datasets } = getReportData(
        reportNumberReservationsByPrice,
        "total_rooms"
      );
      reportLabels = {
        ...reportLabels,
        ...Object.values(legendLabels),
      };
      setDataReservationPrice({
        labels,
        datasets,
      });
    }
    setLegendLabels(Object.values(reportLabels));
  };

  useEffect(() => {
    // report by number reservations
    const reportNumberReservations =
      reportData?.top_reservations_room_each_days;
    const reportNumberReservationsByPrice =
      reportData?.top_number_rooms_each_days;
    setReportNumberReservations(
      reportNumberReservations,
      reportNumberReservationsByPrice
    );

    // report by price
    const topReservationOverview = reportData?.top_reservation_overview;
    const topByWeekdayPriceRange = reportData?.top_by_weekday_price_range;
    setReportPriceData(topReservationOverview, topByWeekdayPriceRange);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  const ChartLabels = ({ labels }) => {
    return (
      <Box
        className={`${styles["report-item"]} ${styles["grouped-labels"]} ${styles["grouped-labels-grid"]}`}
      >
        {labels?.map((item, index) => (
          <Box className={styles["grouped-label"]} key={index}>
            <Box
              className={styles["grouped-label-color"]}
              sx={{ backgroundColor: item.color }}
            ></Box>
            <span>{item.title}</span>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <>
      {loading.dayOfWeek ? (
        <LoadingReport />
      ) : (
        <Box className={styles["report-items"]}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Box className={styles["report-item"]}>
              <Typography className={styles["report-item-title"]}>
                客室タイプ別予約件数
                <span className={styles["report-item-title--span"]}>
                  (予約実績)
                </span>
              </Typography>
              <Box>
                <Bar options={options} data={dataReservation} />
              </Box>
            </Box>
            <Box className={styles["report-item"]}>
              <Typography className={styles["report-item-title"]}>
                宿泊単価別予約件数
                <span className={styles["report-item-title--span"]}>
                  (予約実績)
                </span>
              </Typography>
              <Box>
                <Bar options={options} data={dataReservationPrice} />
              </Box>
            </Box>
            {<ChartLabels labels={legendLabels} />}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Box className={styles["report-item"]}>
              <Typography className={styles["report-item-title"]}>
                客室タイプ別宿泊件数
                <span className={styles["report-item-title--span"]}>
                  (宿泊実績)
                </span>
              </Typography>
              <Box>
                <Bar options={options} data={dataReservationOverview} />
              </Box>
            </Box>
            <Box className={styles["report-item"]}>
              <Typography className={styles["report-item-title"]}>
                宿泊単価別宿泊件数
                <span className={styles["report-item-title--span"]}>
                  (宿泊実績)
                </span>
              </Typography>
              <Box>
                <Bar options={options} data={dataByPrices} />
              </Box>
            </Box>
            <Box
              className={`${styles["report-item"]} ${styles["grouped-labels"]} ${styles["grouped-labels-grid"]} ${styles["grouped-labels-grid-multiple"]}`}
            >
              {priceRanges.map((item, index) => (
                <Box className={styles["grouped-label"]} key={index}>
                  <Box
                    className={styles["grouped-label-color"]}
                    sx={{ backgroundColor: item.color }}
                  ></Box>
                  <span>
                    {index !== priceRanges?.length - 1 && "~"}¥
                    {CurrencyFormat(item.to)}
                    {index + 1 === priceRanges?.length && "~"}
                  </span>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
const ReportPdf = (props) => {
  const {
    dateRange = getDefaultDateRange(),
    priceRanges = DEFAULT_PRICE_RANGE,
  } = props;
  const [isDownloading, setIsDownloading] = useState(false);
  const [pdfInstance, updatePdfInstance] = usePDF({
    document: <></>,
  });
  const { loading } = useSelector((state) => state.report);

  const isLoading = Object.values(loading).includes(true);

  useEffect(() => {
    if (pdfInstance.url && isDownloading) {
      triggerDownload(pdfInstance.url, "レポート_" + dateRangeString);
      setIsDownloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloading, pdfInstance.url]);

  const handleDownloadReports = async () => {
    setIsDownloading(true);
    const reports = document.getElementsByClassName("reportContainer");
    async function createImages(reportElements) {
      const images = await Promise.all(
        [].slice.call(reportElements).map(async (element) => {
          const canvas = await html2canvas(element);
          const data = canvas.toDataURL("image/jpg");
          return data;
        })
      );
      return images;
    }
    const images = await createImages(reports);
    updatePdfInstance(<ReportChartsDocument images={images} />);
  };

  const dateRangeString = useMemo(() => {
    const [start, end] = dateRange;
    return `${moment(start).format("YYYY年MM月DD日")}〜${moment(end).format(
      "YYYY年MM月DD日"
    )}`;
  }, [dateRange]);

  const DownloadButton = useMemo(() => {
    return (
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#ddd",
          p: "10px 20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            color: "#2E4E76",
            display: "flex",
            alignItems: "center",
            textTransform: "none",
          }}
          component={Button}
          disabled={isLoading || isDownloading}
          onClick={handleDownloadReports}
        >
          {isDownloading && <CircularProgress size={16} />}
          <FileDownloadOutlinedIcon />
          .pdf
        </Box>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isDownloading]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden",
      }}
    >
      {DownloadButton}
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <div className="reportContainer">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>概要</Typography>
          </Box>
          <ReportOverviewPdf dateRange={dateRange}></ReportOverviewPdf>
        </div>
        <div className="reportContainer">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>プランランキング</Typography>
          </Box>
          <ReportPlanRankingPdf dateRange={dateRange}></ReportPlanRankingPdf>
        </div>
        <div className="reportContainer">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>プラン×部屋タイプランキング</Typography>
          </Box>
          <ReportPlanRoomTypeRankingPdf
            dateRange={dateRange}
          ></ReportPlanRoomTypeRankingPdf>
        </div>
        <div className="reportContainer">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>プラン×曜日別データ</Typography>
          </Box>
          <ReportPlanByDayOfWeekPdf
            dateRange={dateRange}
          ></ReportPlanByDayOfWeekPdf>
        </div>
        <div className="reportContainer">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>部屋タイプランキング</Typography>
          </Box>
          <ReportRoomTypeRankingPdf
            dateRange={dateRange}
          ></ReportRoomTypeRankingPdf>
        </div>
        <div className="reportContainer">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>宿泊単価別リードタイム×予約件数</Typography>
          </Box>
          <ReportByPricePdf
            dateRange={dateRange}
            priceRanges={priceRanges}
          ></ReportByPricePdf>
        </div>
        <div className="reportContainer">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>曜日別データ</Typography>
          </Box>
          <ReportByDayOfWeekPdf
            dateRange={dateRange}
            priceRanges={priceRanges}
          ></ReportByDayOfWeekPdf>
        </div>
      </Box>
    </Box>
  );
};

export default ReportPdf;
