import { Box } from "@mui/material";
import styles from "../styles.module.scss";
import ReportLayout from "../../../components/ReportLayout";
import ReportStackBarChart from "../../../components/ReportHorizontalChart/ReportStackBarChart";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlanByDayOfWeek } from "../../../features/report/reportSlice";
import { getDefaultDateRange, getStartEndDateString } from "../../../helper";

const ReportPlanByDayOfWeek = () => {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.report.planByWeek);
  const [transformData, setTransformData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [dateRange, setDateRange] = useState(getDefaultDateRange());

  useEffect(() => {
    dispatch(getPlanByDayOfWeek(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    let maxValue = 0;
    const transformData = reportData
      ?.map((item) => {
        if (item.total.count > maxValue) {
          maxValue = item.total.count;
        }
        const days = Object.values(item.days);
        return {
          ...item,
          max_count_days: Math.max(...days),
          total: {
            color: item.total.color,
            value: item.total.count,
            title: item.total.group_plan_name,
          },
        };
      })
      .sort((a, b) => {
        return b.total.value - a.total.value;
      });
    setTransformData(transformData);
    setMaxValue(maxValue);
  }, [reportData]);

  const calculateOpacity = (value, maxValue) => {
    return value / (maxValue || 1);
  };

  const weekDays = [
    {
      id: "sun",
      title: "日曜日",
    },
    {
      id: "mon",
      title: "月曜日",
    },
    {
      id: "tue",
      title: "火曜日",
    },
    {
      id: "wed",
      title: "水曜日",
    },
    {
      id: "thu",
      title: "木曜日",
    },
    {
      id: "fri",
      title: "金曜日",
    },
    {
      id: "sat",
      title: "土曜日",
    },
  ];

  return (
    <ReportLayout
      reportTitle="プラン×曜日別データ"
      reportTooltip={'曜日ごとに最適なプランを見つけることができます。\nそれぞれの曜日に対して、どのようなプランが最適なのかを確認してください。'}
      pageNumber={4}
      onChangeDateRange={(newDates) => setDateRange(newDates)}
    >
      <Box className={styles["report-ranking"]}>
        <table
          className={`${styles["report-table"]} ${styles["report-table--ranking"]} ${styles["report-table--by-days"]}`}
        >
          <thead>
            <tr>
              <th>プラングループ名</th>
              {weekDays.map((day, index) => (
                <th key={index}>{day.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {transformData.map((item, index) => (
              <Fragment key={index}>
                <tr className={styles["detail-row"]}>
                  <td className={styles['cell-title']} title={item.total.title}>
                    {item.total.title}

                    <div className={styles["chart-container"]}>
                      <ReportStackBarChart
                        data={item}
                        limitWidth={1}
                        maxValue={maxValue}
                      />
                    </div>
                  </td>
                  {weekDays.map((day, dayIndex) => {
                    const opacity =
                      calculateOpacity(
                        item.days[day.id],
                        item.max_count_days
                      ) || 0.05;
                    return (
                      <td key={dayIndex}>
                        <Box
                          className={styles["cell-date"]}
                          sx={{
                            opacity,
                          }}
                        ></Box>
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </Box>
    </ReportLayout>
  );
};
export default ReportPlanByDayOfWeek;
