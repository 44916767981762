import { Box } from "@mui/material";
import styles from "../styles.module.scss";
import ReportLayout from "../../../components/ReportLayout";
import ReportStackBarChart from "../../../components/ReportHorizontalChart/ReportStackBarChart";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlanTopRoomType } from "../../../features/report/reportSlice";
import {
  CurrencyFormat,
  getDefaultDateRange,
  getStartEndDateString,
} from "../../../helper";

const ReportRoomTypeRanking = () => {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.report.roomType);
  const [transformData, setTransformData] = useState([]);
  const [maxCount, setMaxCount] = useState(0);
  const [dateRange, setDateRange] = useState(getDefaultDateRange());

  useEffect(() => {
    dispatch(getPlanTopRoomType(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    let maxCount = 0;
    const transformData = reportData
      .map((item) => {
        if (item.number_nights > maxCount) {
          maxCount = item.number_nights;
        }
        return {
          ...item,
          total: {
            color: item.color,
            title: item.title,
            value: item.number_nights,
          },
        };
      })
      .sort((a, b) => {
        return b.value - a.value;
      });
    setTransformData(transformData);
    setMaxCount(maxCount);
  }, [reportData]);

  return (
    <ReportLayout
      reportTitle="部屋タイプランキング"
      reportTooltip={'部屋タイプごとに売上を最大化するための手段を見つけることができます。\n部屋タイプごとの泊数や予約数など売上に対する貢献度を見て、部屋数や単価などのポテンシャルと掛け合わせて考え、部屋ごとに注力するべきポイントを検討してください。'}
      pageNumber={4}
      onChangeDateRange={(newDates) => setDateRange(newDates)}
    >
      <Box className={styles["report-ranking"]}>
        <table
          className={`${styles["report-table"]} ${styles["report-table--ranking"]} ${styles["report-table--by-days"]}`}
        >
          <thead>
            <tr>
              <th>プラングループ名</th>
              <th>泊数</th>
              <th>予約件数</th>
              <th>平均単価</th>
              <th>売上</th>
            </tr>
          </thead>
          <tbody>
            {transformData.map((item, index) => (
              <Fragment key={index}>
                <tr className={styles["detail-row"]}>
                  <td>
                    {item.title}
                    <div className={styles["chart-container"]}>
                      <ReportStackBarChart
                        data={item}
                        limitWidth={1}
                        maxValue={maxCount}
                      />
                    </div>
                  </td>
                  <td className={styles['text-center']}>{CurrencyFormat(item.number_nights)}</td>
                  <td className={styles['text-center']}>{CurrencyFormat(item.number_reservations)}</td>
                  <td>¥{CurrencyFormat(item.average_price)}</td>
                  <td>¥{CurrencyFormat(item.total_fee)}</td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
        <Box className={styles["grouped-labels"]}>
          <Box className={styles["grouped-label"]}>
            <Box
              className={styles["grouped-label-color"]}
              sx={{ backgroundColor: "#172B4D" }}
            ></Box>
            <span>泊数</span>
          </Box>
        </Box>
      </Box>
    </ReportLayout>
  );
};
export default ReportRoomTypeRanking;
