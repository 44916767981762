import { Box } from "@mui/material";
import styles from "../styles.module.scss";
import ReportLayout from "../../../components/ReportLayout";
import ReportStackBarChart from "../../../components/ReportHorizontalChart/ReportStackBarChart";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlanRoomTypeRanking } from "../../../features/report/reportSlice";
import {
  CurrencyFormat,
  getDefaultDateRange,
  getStartEndDateString,
} from "../../../helper";

const ReportPlanRoomTypeRanking = () => {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.report.planRoomType);
  const [transformData, setTransformData] = useState([]);
  const [maxCount, setMaxCount] = useState(0);
  const [dateRange, setDateRange] = useState(getDefaultDateRange());

  useEffect(() => {
    dispatch(getPlanRoomTypeRanking(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    let maxCount = 0;
    const transformData = reportData.map((item) => {
      const reportData = item.report_data;
      const total = reportData[0];
      const groups = reportData.slice(1);
      maxCount = Math.max(maxCount, total.value);
      return {
        ...item,
        total,
        groups,
      };
    });
    setMaxCount(maxCount);
    setTransformData(transformData);
  }, [reportData]);

  return (
    <ReportLayout
      reportTitle="プラン×部屋タイプランキング"
      reportTooltip={'部屋タイプごとに相性の良いプランを見つけることができます。\n部屋タイプごとに売れやすいプランの傾向や、逆にプランに対して売れやすい部屋タイプの傾向を確認してください。'}
      pageNumber={3}
      onChangeDateRange={(newDates) => setDateRange(newDates)}
    >
      <Box className={styles["report-ranking"]}>
        <table
          className={`${styles["report-table"]} ${styles["report-table--ranking"]}`}
        >
          <thead>
            <tr>
              <th>プラングループ名</th>
              <th>泊数</th>
              <th>予約件数</th>
              <th>平均単価</th>
              <th>売上</th>
            </tr>
          </thead>
          <tbody>
            {transformData.map((report, index) => (
              <Fragment key={index}>
                <tr className={styles["detail-row"]}>
                  <td>{report.group_plan_name}</td>
                  <td>{CurrencyFormat(report.number_nights)}</td>
                  <td>{CurrencyFormat(report.number_reservations)}</td>
                  <td>¥{CurrencyFormat(report.avarege_price)}</td>
                  <td>¥{CurrencyFormat(report.total_fee)}</td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <ReportStackBarChart data={report} maxValue={maxCount} />
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
        <Box className={styles["grouped-labels"]}>
          <Box className={styles["grouped-label"]}>
            <Box
              className={styles["grouped-label-color"]}
              sx={{ backgroundColor: transformData[0]?.total.color }}
            ></Box>
            <span>{transformData[0]?.total.title}</span>
          </Box>
          {transformData[0]?.groups.map((item, index) => (
            <Box className={styles["grouped-label"]} key={index}>
              <Box
                className={styles["grouped-label-color"]}
                sx={{ backgroundColor: item.color }}
              ></Box>
              <span>{item.title}</span>
            </Box>
          ))}
        </Box>
      </Box>
    </ReportLayout>
  );
};
export default ReportPlanRoomTypeRanking;
