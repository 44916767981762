import { Box, TableCell, TableRow, Typography } from "@mui/material";
import BreadCrumb from "../../../../components/breadCrumb";
import PlanGroupContainer from "../../../../components/PlanGroupContainer";
import styles from "../styles.module.scss";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMultiGroupPlan,
  getPlanGroups,
} from "../../../../features/plan/planGroupSlice";
import { toast } from "react-toastify";
import { UNREGISTER, PLAN_TIME_FORMAT } from "../../../../constants";
import PaginationPage from "../../../../components/pagination";
import { CurrencyFormat } from "../../../../helper";
import { useHistory } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import EditGroupPopover from "../../../../components/EditPlanGroup";

const textRoutes = [
  "ダッシュボード",
  "実績登録・確認",
  "プラン販売実績",
  "プラングループ",
];

export default function PlanResultGroup() {
  const dispatch = useDispatch();
  const [dataGroups, setDataGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { groups, paginate } = useSelector((state) => state.planGroup);
  const [totalDetail, setTotalDetail] = useState({
    totalNumberOfReservation: 0,
    totalReservationAmount: 0,
    totalAvaregePrice: 0,
  });
  const [timeRange, setTimeRange] = useState({
    start_time: moment().startOf("month").format(PLAN_TIME_FORMAT),
    end_time: moment().endOf("month").format(PLAN_TIME_FORMAT),
  });
  const [searchValue, setSearchValue] = useState("");
  const [anchorEditGroup, setAnchorEditGroup] = useState(null);
  const [editingGroup, setEditingGroup] = useState(null);

  const handleClickEditGroup = (event, group) => {
    setEditingGroup({ id: group?.id, name: group?.plan_group_name });
    setAnchorEditGroup(event.currentTarget);
  };

  const handleCloseEditGroup = () => {
    setAnchorEditGroup(null);
    setEditingGroup(null);
  };

  useEffect(() => {
    const dataChange = [];
    let totalNumberOfReservation = 0;
    let totalReservationAmount = 0;
    let totalAvaregePrice = 0;

    groups?.forEach((item) => {
      let obj = {
        id: item?.id,
        plan_group_name: item?.name,
        sales_period:
          item?.sale_period_start && item?.sale_period_end
            ? `${item?.sale_period_start}~${item?.sale_period_end}`
            : UNREGISTER,
        number_of_reservations: CurrencyFormat(item?.number_reservations),
        reservation_amount: "¥" + CurrencyFormat(item?.reservation_amount),
        avarege_price: "¥" + CurrencyFormat(item?.avarege_price),
      };
      totalNumberOfReservation += item?.number_reservations;
      totalReservationAmount += item?.reservation_amount;
      totalAvaregePrice += item?.avarege_price;
      dataChange.push(obj);
    });
    setTotalDetail({
      totalNumberOfReservation: CurrencyFormat(totalNumberOfReservation),
      totalReservationAmount: "¥" + CurrencyFormat(totalReservationAmount),
      totalAvaregePrice: "¥" + CurrencyFormat(totalAvaregePrice),
    });
    setDataGroups(dataChange);
  }, [groups]);

  const fetchGroups = async () => {
    dispatch(
      getPlanGroups({
        page: currentPage,
        search_param: searchValue,
        ...timeRange,
      })
    );
  };

  const updateGroupDetail = (groupNewDetail) => {
    const copyPlanGroups = [...dataGroups];
    const planGroupIndex = copyPlanGroups.findIndex(
      (group) => group.id === groupNewDetail.id
    );
    copyPlanGroups[planGroupIndex] = {
      ...copyPlanGroups[planGroupIndex],
      ...groupNewDetail,
    };
    setDataGroups(copyPlanGroups);
  };

  const CellAction = ({ row }) => {
    const history = useHistory();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
    const handleOpenCellAction = (id) => {
      const url = `/results/plan/group/${id}`;
      history.push(url);
    };
    return (
      <>
        <Box
          id={row.id}
          sx={{ display: "flex", alignItems: "center", position: "relative" }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Typography
            sx={{ cursor: "pointer", color: "#7764E4", pr: 2 }}
            onClick={() => handleOpenCellAction(row.id)}
          >
            {row.plan_group_name}
          </Typography>
          <Box
            sx={{
              position: "absolute",
              cursor: "pointer",
              color: "#8f8f9f",
              right: "0",
            }}
            onClick={(event) => handleClickEditGroup(event, row)}
          >
            <EditIcon
              sx={{
                fontSize: "17px",
                opacity: isHovered ? 1 : 0,
              }}
            />
          </Box>
        </Box>
      </>
    );
  };

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue, timeRange]);

  const table = useMemo(() => {
    const headCells = [
      {
        id: "plan_group_name",
        numeric: false,
        disablePadding: true,
        label: "プラングループ名",
        color: "#7764E4",
        options: {
          component: (row) => <CellAction row={row} />,
        },
      },
      {
        id: "number_of_reservations",
        numeric: false,
        disablePadding: false,
        label: "予約件数",
      },
      {
        id: "reservation_amount",
        numeric: false,
        disablePadding: false,
        label: "予約金額",
      },
      {
        id: "avarege_price",
        numeric: false,
        disablePadding: false,
        label: "平均単価",
      },
    ];
    const pdfHeaders = [
      {
        title: "プラングループ名",
        id: "plan_group_name",
        width: "55%",
      },
      {
        title: "予約件数",
        id: "number_of_reservations",
        width: "15%",
      },
      {
        title: "予約金額",
        id: "reservation_amount",
        width: "15%",
      },
      {
        title: "平均単価",
        id: "avarege_price",
        width: "15%",
      },
    ];

    const totalHeaders = [
      {
        title: "合計",
        value: "合計",
        width: "55%",
        styles: {
          textAlign: "left",
          color: "#172B4D",
        },
      },
      {
        title: "予約件数",
        value: totalDetail.totalNumberOfReservation,
        width: "15%",
        styles: {
          color: "#2E4E76",
        },
      },
      {
        title: "予約金額",
        value: totalDetail.totalReservationAmount,
        width: "15%",
        styles: {
          color: "#2E4E76",
        },
      },
      {
        title: "平均単価",
        value: totalDetail.totalAvaregePrice,
        width: "15%",
        styles: {
          color: "#2E4E76",
        },
      },
    ];

    const handleDeleteGroups = async (rows) => {
      const res = await dispatch(deleteMultiGroupPlan(rows));
      if (res.payload.error) {
        toast.error("システムエラー");
      }
    };

    const triggerDeleteGroup = async (showPreviousPage) => {
      // check rows = 0
      if (currentPage > 1 && showPreviousPage === true) {
        setCurrentPage(currentPage - 1);
      } else {
        fetchGroups();
      }
    };

    const handleSearch = async (dataSearch) => {
      if (
        dataSearch.time_range?.start_time !== timeRange?.start_time ||
        dataSearch.time_range?.end_time !== timeRange?.end_time
      ) {
        setTimeRange(dataSearch.time_range);
      }
      setSearchValue(dataSearch.search_param || "");
      setCurrentPage(1);
    };

    function TableFooter() {
      return (
        <TableRow className={styles["table-footer-row"]}>
          <TableCell></TableCell>
          <TableCell
            sx={{
              borderRight: "2px solid #8F8F9F",
              boxShadow: "3px 0px 0px #2C28281C",
            }}
          >
            合計
          </TableCell>
          <TableCell>{totalDetail.totalNumberOfReservation}</TableCell>
          <TableCell>{totalDetail.totalReservationAmount}</TableCell>
          <TableCell>{totalDetail.totalAvaregePrice}</TableCell>
        </TableRow>
      );
    }

    return (
      <PlanGroupContainer
        rows={dataGroups}
        headCells={headCells}
        onEventTriggerSearch={handleSearch}
        onEventTriggerDelete={triggerDeleteGroup}
        handleDeleteRows={handleDeleteGroups}
        onEventTriggerCreate={fetchGroups}
        TableFooter={TableFooter}
        pdfHeaders={pdfHeaders}
        totalPdfDetail={totalHeaders}
      >
        {paginate?.total_pages > 1 && (
          <PaginationPage
            count={paginate.total_pages}
            onChange={(event, value) => setCurrentPage(value)}
            variant="outlined"
            shape="rounded"
            page={currentPage}
          />
        )}
      </PlanGroupContainer>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGroups, paginate, currentPage]);

  return (
    <Box
      component="main"
      sx={{
        height: "100%",
        overflow: "hidden",
        maxHeight: "100%",
        p: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BreadCrumb content="実績登録・確認" textRoutes={textRoutes} />
      {table}
      <EditGroupPopover
        container={anchorEditGroup}
        selectedGroup={editingGroup}
        onClose={handleCloseEditGroup}
        onUpdated={updateGroupDetail}
      />
    </Box>
  );
}
