import { Box, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import ReportBarChart from "../../components/ReportHorizontalChart";
import ReportLayout from "../../components/ReportLayout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOverviewReport } from "../../features/report/reportSlice";
import {
  CurrencyFormat,
  getDefaultDateRange,
  getStartEndDateString,
} from "../../helper";

const chartLabels = {
  top_group_plans_number_nights: {
    title: "プランランキング",
    name_col: "プラングループ名",
    count_col: {
      id: "number_nights",
      label: "泊数",
    },
    revenue_col: "total_fee",
  },
  top_lead_time_ranking: {
    title: "リードタイムランキング",
    name_col: "リードタイム",
    count_col: {
      id: "count_lead_time",
      label: "泊数",
    },
    revenue_col: "total_fee",
  },
  top_room: {
    title: "ルームタイプ別ランキング",
    name_col: "ルームタイプ名",
    count_col: {
      id: "number_nights",
      label: "泊数",
    },
    revenue_col: "total_fee",
  },
  top_nights_stay: {
    title: "泊数/滞在別",
    name_col: "泊数",
    count_col: {
      id: "plan_count",
      label: "件数",
    },
    revenue_col: "total_fee",
  },
};

const colorConfig = {
  count_col: "#7764E4",
  revenue_col: "#CE858F",
};

const Report = () => {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.report.data);
  const [maxValue, setMaxValue] = React.useState({});
  const [dateRange, setDateRange] = React.useState(getDefaultDateRange());

  useEffect(() => {
    dispatch(getOverviewReport(getStartEndDateString(dateRange)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    const max = {};
    Object.keys(reportData).forEach((reportKey) => {
      reportData[reportKey].forEach((row) => {
        const countCol = chartLabels[reportKey]?.count_col?.id;
        const revenueCol = "total_fee";

        const countValue = row[countCol];
        const revenueValue = row[revenueCol];
        if (!max[reportKey]) {
          max[reportKey] = {};
        }
        if (max[reportKey].count < countValue || !max[reportKey].count) {
          max[reportKey].count = countValue;
        }

        if (
          max[reportKey][revenueCol] < revenueValue ||
          !max[reportKey][revenueCol]
        ) {
          max[reportKey][revenueCol] = revenueValue;
        }
      });
    });
    setMaxValue(max);
  }, [reportData]);

  const convertToDataChart = (data, chartKey) => {
    return [
      {
        value: data[chartLabels[chartKey]?.count_col?.id] || 0,
        color: colorConfig.count_col,
        id: "count",
      },
      {
        value: data.total_fee,
        color: colorConfig.revenue_col,
        id: "total_fee",
      },
    ];
  };

  return (
    <ReportLayout
      reportTitle="概要"
      reportTooltip={'概要データからは、販売するプランや施策のおおまかな戦略の方向性を見つけることができます。\n売上に寄与している傾向や要素を理解し、どのような要素が自社の売上にとって大きく影響しているのかを確認してください。'}
      onChangeDateRange={(newDates) => setDateRange(newDates)}
    >
      <Box className={styles["report-items"]}>
        {Object.entries(reportData).map(([key, chartData]) => (
          <Box className={styles["report-item"]} key={key}>
            <Typography className={styles["report-item-title"]}>
              {chartLabels[key]?.title || "未知のグラフタイプ"}
            </Typography>
            <table className={styles["report-table"]}>
              <thead>
                <tr>
                  <th>{chartLabels[key]?.name_col || "名前"}</th>
                  <th>{chartLabels[key]?.count_col?.label || "件数"}</th>
                  <th>売上</th>
                </tr>
              </thead>
              <tbody>
                {Array.from(chartData)?.map((row, index) => (
                  <React.Fragment key={index}>
                    <tr className={styles["detail-row"]}>
                      <td>
                        <p>{row.label_name}</p>
                      </td>
                      <td>
                        {CurrencyFormat(row[chartLabels[key]?.count_col?.id])}
                      </td>
                      <td>¥{CurrencyFormat(row["total_fee"])}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <ReportBarChart
                          maxData={maxValue[key]}
                          data={convertToDataChart(row, key)}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </Box>
        ))}
      </Box>
    </ReportLayout>
  );
};
export default Report;
