/* eslint-disable react-hooks/exhaustive-deps */
import { Box, TableCell, TableRow } from "@mui/material";
import BreadCrumb from "../../../../components/breadCrumb";
import PlanGroupContainer from "../../../../components/PlanGroupContainer";
import styles from "../styles.module.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMultiPlans,
  getListPlan,
} from "../../../../features/plans/plansSlice";
import PaginationPage from "../../../../components/pagination";
import { toast } from "react-toastify";
import { CurrencyFormat } from "../../../../helper";
import { PLAN_TIME_FORMAT, UNREGISTER_GROUP } from "../../../../constants";
import moment from "moment";

const textRoutes = [
  "ダッシュボード",
  "実績登録・確認 ",
  "プラン販売実績 ",
  "プラン",
];

export default function PlanResultPlans() {
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.plans);
  const planList = plans.list;
  const paginate = plans.paginate;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [transformRows, setTransformRows] = useState([]);
  const [timeRange, setTimeRange] = useState({
    start_time: moment().startOf("month").format(PLAN_TIME_FORMAT),
    end_time: moment().endOf("month").format(PLAN_TIME_FORMAT),
  });

  const [total, setTotal] = useState({
    reservation_amount: 0,
    avarege_price: 0,
  });

  const handleSearchPlans = async (dataSearch) => {
    setCurrentPage(1);
    setSearchValue(dataSearch.search_param || "");
    if (
      dataSearch.time_range?.start_time !== timeRange?.start_time ||
      dataSearch.time_range?.end_time !== timeRange?.end_time
    ) {
      setTimeRange(dataSearch.time_range);
    }
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "プラン名",
    },
    {
      id: "group_plan.name",
      numeric: false,
      disablePadding: true,
      label: "プラングループ",
      color: "#7764E4",
      options: {
        emptyText: UNREGISTER_GROUP,
        emptyTextColor: "#2E4E76",
        link: (row) => {
          return `/results/plan/group/${row?.group_plan?.id}`;
        },
      },
    },
    {
      id: "number_reservations",
      numeric: false,
      disablePadding: false,
      label: "予約件数",
      options: {
        nowrap: true,
      },
    },
    {
      id: "reservation_amount",
      numeric: false,
      disablePadding: false,
      label: "予約金額",
      options: {
        nowrap: true,
      },
    },
    {
      id: "avarege_price",
      numeric: false,
      disablePadding: false,
      label: "平均単価",
      prefix: "¥ ",
      options: {
        nowrap: true,
      },
    },
  ];

  const fetchPlans = async () => {
    dispatch(
      getListPlan({
        page: currentPage,
        search_param: searchValue,
        ...timeRange,
      })
    );
  };

  useEffect(() => {
    fetchPlans();
  }, [currentPage, searchValue, timeRange]);

  useEffect(() => {
    if (planList) {
      let totalNumberReservations = 0;
      let totalReservationAmount = 0;
      let totalAvaregePrice = 0;

      const transform = planList.map((item) => {
        totalNumberReservations += item.number_reservations;
        totalReservationAmount += item.reservation_amount;
        totalAvaregePrice += item.avarege_price;

        return {
          ...item,
          number_reservations: CurrencyFormat(item.number_reservations),
          reservation_amount: "¥" + CurrencyFormat(item.reservation_amount),
          avarege_price: "¥" + CurrencyFormat(item.avarege_price),
        };
      });
      setTransformRows(transform);
      setTotal({
        number_reservations: CurrencyFormat(totalNumberReservations),
        reservation_amount: "¥" + CurrencyFormat(totalReservationAmount),
        avarege_price: "¥" + CurrencyFormat(totalAvaregePrice),
      });
    }
  }, [planList]);

  const pdfHeaders = [
    {
      title: "プラン名",
      id: "name",
      width: "40%",
    },
    {
      title: "プラングループ名",
      id: "group_plan.name",
      width: "20%",
      bodyColor: "#172B4D",
      styles: {
        textAlign: "left",
        color: "#3F7EAE",
      },
    },
    {
      title: "予約件数",
      id: "number_reservations",
      width: "10%",
    },
    {
      title: "予約金額",
      id: "reservation_amount",
      width: "10%",
    },
    {
      title: "平均単価",
      id: "avarege_price",
      width: "10%",
    },
  ];

  const totalHeaders = [
    {
      title: "合計",
      value: "合計",
      width: "40%",
      styles: {
        textAlign: "left",
        color: "#172B4D",
      },
    },
    {
      title: "",
      value: "",
      width: "20%",
    },
    {
      title: "予約件数",
      id: "number_reservations",
      value: total.number_reservations,
      width: "10%",
      styles: {
        color: "#2E4E76",
      },
    },
    {
      title: "予約金額",
      id: "reservation_amount",
      value: total.reservation_amount,
      width: "10%",
      styles: {
        color: "#2E4E76",
      },
    },
    {
      title: "平均単価",
      id: "avarege_price",
      value: total.avarege_price,
      width: "10%",
      styles: {
        color: "#2E4E76",
      },
    },
  ];

  const handleDeletePlans = async (rows) => {
    try {
      const res = await dispatch(deleteMultiPlans(rows));
      if (res.payload.error) {
        toast.error("システムエラー");
      } else {
        dispatch(getListPlan({ page: currentPage }));
      }
    } catch (error) {
      toast.error("システムエラー");
    }
  };

  const triggerDeletePlan = async (showPreviousPage) => {
     // check rows = 0
     if (currentPage > 1 && showPreviousPage === true) {
      setCurrentPage(currentPage - 1);
    } else {
      fetchPlans();
    }
  }

  function TableFooter() {
    return (
      <TableRow className={styles["table-footer-row"]}>
        <TableCell></TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #8F8F9F",
          }}
        >
          合計
        </TableCell>
        <TableCell></TableCell>
        <TableCell>{total.number_reservations}</TableCell>
        <TableCell>{total.reservation_amount}</TableCell>
        <TableCell>{total.avarege_price}</TableCell>
      </TableRow>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 4,
        height: "100%",
        overflow: "hidden",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BreadCrumb content="実績登録・確認" textRoutes={textRoutes} />
      <PlanGroupContainer
        rows={transformRows}
        headCells={headCells}
        TableFooter={TableFooter}
        handleDeleteRows={handleDeletePlans}
        onEventTriggerDelete={triggerDeletePlan}
        isPlans
        onUpdatedPlans={fetchPlans}
        onEventTriggerSearch={handleSearchPlans}
        pdfHeaders={pdfHeaders}
        totalPdfDetail={totalHeaders}
      >
        {paginate?.total_pages > 1 && (
          <PaginationPage
            count={paginate.total_pages}
            onChange={(event, value) => setCurrentPage(value)}
            variant="outlined"
            shape="rounded"
            page={currentPage}
          />
        )}
      </PlanGroupContainer>
    </Box>
  );
}
